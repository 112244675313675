import { useMarketplaceStore } from "@/store/MarketplaceStore";

import AccountService from "@/core/modules/account/services/AccountService";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import { BadRequestApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import CampaignService from "@/modules/campaign/services/CampaignService";

import { Onboarding } from "@/modules/onboarding/shared/Onboarding";

export default class OnboardingGuard {
  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async withCompleteOnboarding(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (!AccountHandler.isAuthenticated()) {
      return next({
        name: "Login",
      });
    }

    const accountInfo = await AccountHandler.getInfo();
    useMarketplaceStore().updateLocale(
      String(accountInfo.address_country_code)
    );
    if (!accountInfo) {
      return next({
        name: "Login",
      });
    }

    const conditionEmailVerified =
      accountInfo?.email != null && accountInfo?.email_verified;
    if (!conditionEmailVerified) {
      return next({
        name: "ConfirmEmail",
      });
    }

    const position = accountInfo?.onboard_position.toString();
    const sequence = {
      1: "PersonalInformation", //1
      2: "AboutMe", //2
      3: "SocialNetworks", //3
      4: "Categories", //4
      5: "SamplePhoto", //5
      6: "SampleVideo", //6
      7: "Disclaimer", //7
      8: "Summary", //8
      9: "ApplicationSent", //9
    };
    if (Object.keys(sequence).includes(position)) {
      return next({
        name: Reflect.get(sequence, position),
      });
    }

    next();
  }

  static async withIncompleteOnboarding(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (!AccountHandler.isAuthenticated()) {
      return next({
        name: "Login",
      });
    }

    const accountInfo = await AccountHandler.getInfo();
    useMarketplaceStore().updateLocale(
      String(accountInfo.address_country_code)
    );

    if (!accountInfo) {
      return next({
        name: "Login",
      });
    }

    const conditionEmailVerified =
      accountInfo?.email != null && accountInfo?.email_verified;
    if (!conditionEmailVerified) {
      return next({
        name: "ConfirmEmail",
      });
    }

    //The onboarding is completed, redirect to the dashboard.
    if (accountInfo.onboard_position > Onboarding.APPLICATION_SENT) {
      return next({
        name: "Dashboard",
      });
    }

    const sequence = {
      1: "PersonalInformation", //1
      2: "AboutMe", //2
      3: "SocialNetworks", //3
      4: "Categories", //4
      5: "SamplePhoto", //5
      6: "SampleVideo", //6
      7: "Disclaimer", //7
      8: "Summary", //8
      9: "ApplicationSent", //9
    };
    const sequenceId = to.meta.stepperPosition as number;
    //Tries to access a hide step
    if (
      sequenceId < Onboarding.APPLICATION_SENT &&
      sequenceId > Onboarding.CATEGORIES &&
      accountInfo.onboard_position < Onboarding.APPLICATION_SENT &&
      accountInfo.onboard_position > Onboarding.CATEGORIES
    ) {
      return next({
        name: "Categories",
      });
    }
    //The user tries to access a step higher than the current step, redirect to the current step.
    if (sequenceId > accountInfo.onboard_position) {
      return next({
        name: Reflect.get(sequence, accountInfo.onboard_position),
      });
    }
    //The user submitted his application, he can't edit anymore.
    if (
      sequenceId < Onboarding.APPLICATION_SENT &&
      accountInfo.onboard_position === Onboarding.APPLICATION_SENT
    ) {
      return next({
        name: "ApplicationSent",
      });
    }

    //Otherwise, the user can access the page.
    next();
  }

  static async withCompleteOnboardingAndNoJobs(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (!AccountHandler.isAuthenticated()) {
      return next({
        name: "Login",
      });
    }

    const accountInfo = await AccountHandler.getInfo();
    useMarketplaceStore().updateLocale(
      String(accountInfo.address_country_code)
    );
    if (!accountInfo) {
      return next({
        name: "Login",
      });
    }

    const conditionEmailVerified =
      accountInfo?.email != null && accountInfo?.email_verified;
    if (!conditionEmailVerified) {
      return next({
        name: "ConfirmEmail",
      });
    }

    const position = accountInfo?.onboard_position.toString();
    const sequence = {
      1: "PersonalInformation", //1
      2: "AboutMe", //2
      3: "SocialNetworks", //3
      4: "Categories", //4
      5: "SamplePhoto", //5
      6: "SampleVideo", //6
      7: "Disclaimer", //7
      8: "Summary", //8
      9: "ApplicationSent", //9
    };
    if (Object.keys(sequence).includes(position)) {
      return next({
        name: Reflect.get(sequence, position),
      });
    }

    const stats = await AccountService.getStats();
    if (
      stats.nr_jobs === 0 &&
      stats.campaigns_approved === 0 &&
      stats.campaigns_invited === 0
    ) {
      return next({
        name: "Marketplace",
      });
    }

    to.meta.stats = stats;
    next();
  }

  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async withCompleteOnboardingJobTaken(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (!AccountHandler.isAuthenticated()) {
      return next({
        name: "Login",
      });
    }

    const accountInfo = await AccountHandler.getInfo();
    useMarketplaceStore().updateLocale(
      String(accountInfo.address_country_code)
    );
    if (!accountInfo) {
      return next({
        name: "Login",
      });
    }

    const conditionEmailVerified =
      accountInfo?.email != null && accountInfo?.email_verified;
    if (!conditionEmailVerified) {
      return next({
        name: "ConfirmEmail",
      });
    }

    const position = accountInfo?.onboard_position.toString();
    const sequence = {
      1: "PersonalInformation", //1
      2: "AboutMe", //2
      3: "SocialNetworks", //3
      4: "Categories", //4
      5: "SamplePhoto", //5
      6: "SampleVideo", //6
      7: "Disclaimer", //7
      8: "Summary", //8
      9: "ApplicationSent", //9
    };
    if (Object.keys(sequence).includes(position)) {
      return next({
        name: Reflect.get(sequence, position),
      });
    }

    const campaignId = Number(to.params.campaignId);
    try {
      const campaign = await CampaignService.get(campaignId);

      /*
        The CampaignSummary should not be available if the job is already taken
      */
      if (campaign.created_job_id > 0) {
        return next({
          name: "SummaryJob",
          params: { jobId: campaign.created_job_id },
        });
      }

      to.meta.campaign = campaign;
    } catch (error) {
      if (!(error instanceof BadRequestApiServiceError)) {
        throw error;
      }

      return next({
        name: "Marketplace",
      });
    }

    next();
  }
}
