import { defineStore } from "pinia";

import { MarketplaceList } from "@/core/modules/account/enums/MarketplaceEnum";

import i18n from "@/i18n";

export const useMarketplaceStore = defineStore("marketplace", () => {
  function updateLocale(marketplace: string) {
    let locale = "en";
    if (marketplace === MarketplaceList.USMarketplace) {
      locale = "en-US";
    } else if (marketplace === MarketplaceList.UKMarketplace) {
      locale = "en-GB";
    } else if (marketplace === MarketplaceList.CAMarketplace) {
      locale = "en-CA";
    } else if (marketplace === MarketplaceList.AUMarketplace) {
      locale = "en-AU";
    }

    i18n.global.locale.value = locale;
  }

  return {
    updateLocale,
  };
});
