import { defineStore } from "pinia";
import { WebSocketStoreHandler } from "@/store/WebSocketStoreHandler";

import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import { WebSocketService } from "@/core/shared/services/WebSocket/WebSocketService";

export interface MessageSocket {
  status: string;
  type: string;
  data: unknown;
  code?: string;
  message?: string;
  timestamp: number;
}

export interface WebSocketState {
  socket: {
    isConnected: boolean;
    isAuthenticated: boolean;
    message: MessageSocket;
    reconnectError: boolean;
    heartBeatInterval: number;
    heartBeatTimer: number;
  };
  $socket: WebSocket | undefined;
}

export const useWebSocketStore = defineStore("websocket", {
  state: (): WebSocketState => ({
    socket: {
      // Connection Status
      isConnected: false,
      // Authentication Status
      isAuthenticated: false,
      // Message content
      message: {} as MessageSocket,
      // Reconnect error
      reconnectError: false,
      // Heartbeat message sending time
      heartBeatInterval: 50000,
      // Heartbeat timer
      heartBeatTimer: 0,
    },
    $socket: undefined,
  }),
  actions: {
    // Connection open
    onOpen(event: Event): void {
      console.log("Web socket opened");
      this.$socket = event.currentTarget as WebSocket;
      this.socket.isConnected = true;
      // When the connection is successful, start sending heartbeat messages regularly to avoid being disconnected by the server
      this.socket.heartBeatTimer = window.setInterval(() => {
        this.socket.isConnected &&
          this.$socket?.sendObj({
            type: "heartbeat",
            data: [],
          });
      }, this.socket.heartBeatInterval);
      //Authenticate the user against the web socket server if the user is logged in.
      if (AccountHandler.isAuthenticated()) {
        WebSocketService.authenticate();
      }
    },
    // Connection closed
    onClose(event: Event): void {
      console.log("Web socket closed");
      this.socket.isConnected = false;
      this.setAuthenticated(false);
      // Stop the heartbeat message when the connection is closed
      clearInterval(this.socket.heartBeatTimer);
      this.socket.heartBeatTimer = 0;
      console.log("The line is disconnected: " + new Date());
      console.log(event);
    },
    // An error occurred
    onError(event: Event): void {
      console.log("Web socket error");
      console.error(this.$state, event);
      this.socket.isConnected = false;
      this.setAuthenticated(false);
    },
    // Receive the message sent by the server
    onMessage(message: MessageSocket): void {
      this.socket.message = message;
      WebSocketStoreHandler.handle(message);
    },
    // Auto reconnect
    onReconnect(count: number): void {
      console.log(`Web socket reconnected (intent ${count})`);
      //Authenticate the user against the web socket server if the user is logged in.
      if (AccountHandler.isAuthenticated()) {
        WebSocketService.authenticate();
      }
    },
    // Reconnect error
    onReconnectError(): void {
      console.log("Error on web socket reconnection", this.$state);
      this.socket.reconnectError = true;
    },
    set$Socket($socket: WebSocket) {
      this.$socket = $socket;
    },
    setAuthenticated(authenticated = false) {
      this.socket.isAuthenticated = authenticated;
    },
  },
});
