import type {
  FormattedDuration,
  SignedDuration,
} from "@/core/shared/helpers/Datetime.types";

import {
  compareAsc,
  format as formatFns,
  fromUnixTime,
  intervalToDuration,
  lastDayOfMonth,
  parse,
} from "date-fns";
import type { DateTimeFormatResult } from "vue-i18n";

import i18n from "@/i18n";

export default class Datetime {
  static format(date: Date, key = "short"): DateTimeFormatResult {
    return i18n.global.d(date, key);
  }

  static formatUnixTime(unixTime: number, key = "short"): DateTimeFormatResult {
    return this.format(fromUnixTime(unixTime), key);
  }

  static intervalToDuration(
    startUnixTime: number,
    endUnixTime: number
  ): SignedDuration {
    let start = fromUnixTime(startUnixTime);
    let end = fromUnixTime(endUnixTime);
    const past = startUnixTime > endUnixTime;
    if (startUnixTime > endUnixTime) {
      start = fromUnixTime(endUnixTime);
      end = fromUnixTime(startUnixTime);
    }
    //Please note that intervalToDuration doesn't return the number of weeks.
    const duration = intervalToDuration({
      start: start,
      end: end,
    });

    return { ...duration, ...{ past: past } };
  }

  /**
   * Format a duration
   *
   * You can pass a customized array of periods if you want to support seconds for example.
   *
   * @param startUnixTime
   * @param endUnixTime
   * @param maxUnits
   * @param periods
   */
  static formatDuration(
    startUnixTime: number,
    endUnixTime: number,
    maxUnits = 2,
    periods = ["years", "months", "weeks", "days", "hours", "minutes"]
  ): FormattedDuration {
    const duration = Datetime.intervalToDuration(startUnixTime, endUnixTime);
    let text = "";

    if (Math.abs(endUnixTime - startUnixTime) < 60) {
      text += i18n.global.t("core.shared.helpers.units.time.fewSeconds");
    } else {
      let units = 0;
      for (const period of Object.keys(duration)) {
        if (periods.includes(period)) {
          const nb = Reflect.get(duration, period);
          if (nb > 0) {
            if (units >= 1) {
              text += " ";
            }
            text += i18n.global.t(`core.shared.helpers.units.time.${period}`, {
              nb: nb,
            });
            units++;
            if (units >= maxUnits) {
              break;
            }
          }
        }
      }
    }

    const timeText = duration.past
      ? i18n.global.t("core.shared.helpers.units.time.endWord.late", {
          text: text,
        })
      : i18n.global.t("core.shared.helpers.units.time.endWord.left", {
          text: text,
        });

    return {
      duration: duration,
      text: text,
      timeText: timeText,
    };
  }

  static timeFormat(seconds: number): string {
    return (
      Math.floor(seconds / 60)
        .toString()
        .padStart(2, "0") +
      ":" +
      (seconds % 60).toString().padStart(2, "0")
    );
  }

  static dateFormat(date: Date, format: string): string {
    return formatFns(date, format);
  }

  static lastDayOfMonth(date: Date): Date {
    return lastDayOfMonth(date);
  }

  static fromString(date: string, format: string): Date {
    return parse(date, format, new Date());
  }

  //Compare the two dates and return 1 if the first date is after
  // the second, -1 if the first date is before the second
  // or 0 if dates are equal.
  static compareDates(firstDate: Date, secondDate: Date): number {
    return compareAsc(firstDate, secondDate);
  }
}
